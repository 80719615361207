import React, { useState, useEffect } from "react";
import "./Slideshow.css";

interface Slide {
  url: string;
}

interface SlideshowProps {
  slides: Slide[];
  autoSlideDelay?: number;
  itemsPerWindow?: number;
  imageWidth?: number;
  imageHeight?: number;
}

const Slideshow: React.FC<SlideshowProps> = ({
  slides,
  autoSlideDelay = 3000,
  itemsPerWindow = 4,
  imageWidth = 300,
  imageHeight = 200,
}) => {
  const extendedSlides = [...slides, ...slides]; // Duplicate set of slides

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, autoSlideDelay);

    return () => {
      clearInterval(autoSlideInterval);
    };
  }, [currentIndex, autoSlideDelay]);

  return (
    <div className="container mt-5 slideshow-container">
      <button className="arrow left-arrow" onClick={prevSlide}>
        &lt;
      </button>
      <div
        className="slides"
        style={{
          width: `${extendedSlides.length * (100 / itemsPerWindow)}%`,
          transform: `translateX(${-currentIndex * (100 / itemsPerWindow)}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {extendedSlides.map((slide, index) => (
          <img
            key={index}
            src={slide.url}
            alt={`Slide ${index + 1}`}
            className="slide-image"
            style={{
              width: `${100 / itemsPerWindow}%`,
              height: "auto",
              objectFit: "cover",
            }}
          />
        ))}
      </div>
      <button className="arrow right-arrow" onClick={nextSlide}>
        &gt;
      </button>
      <div className="dots-container">
        {slides.map((_, slideIndex) => (
          <div
            key={slideIndex}
            className={`dot ${currentIndex === slideIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(slideIndex)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
