import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cognitoService from "../Auth/cognitoService";
import { loadStripe } from "@stripe/stripe-js";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_test_51Od336LdDG2Sy7GCpWJ3mGH7JgMDk3tLW0W43JBP3ItQELekM11ccMcFI6jjktF0NLf7ZiJ9fpNdy5w2dg2Zag1C00UhNEtyze"
);

interface SubtotalProps {
  items: {
    priceId: string;
    name: string;
    primary_image_link: string;
    quantity: number;
  }[];
}

const Subtotal: React.FC<SubtotalProps> = ({ items }) => {
  const [cartItems, setCartItems] = useState(items);
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState(null);

  // const increaseQuantity = (itemId: string) => {
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.price_id === itemId
  //         ? { ...item, quantity: item.quantity + 1 }
  //         : item
  //     )
  //   );
  // };

  // const decreaseQuantity = (itemId: string) => {
  //   setCartItems((prevItems) =>
  //     prevItems.map((item) =>
  //       item.price_id === itemId && item.quantity > 1
  //         ? { ...item, quantity: item.quantity - 1 }
  //         : item
  //     )
  //   );
  // };

  // const deleteItem = (itemId: string) => {
  //   setCartItems((prevItems) =>
  //     prevItems.filter((item) => item.price_id !== itemId)
  //   );
  // };
  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl; // Redirect to the external URL
    }
  }, [redirectUrl]);

  console.log(cartItems);
  const handleClick = async () => {
    try {
      const userId = await cognitoService.getUserIdFromSession();
      console.log(userId);
      const response = await axios.post(
        "https://jvlnzkr06c.execute-api.us-west-2.amazonaws.com/prod-1/create-payment",
        { items: cartItems, user_id: userId }
        // { headers: headers }
      );
      const url = response.data.url;
      setRedirectUrl(url);
      console.log(url);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  return (
    <Container>
      <Button variant="success" onClick={handleClick}>
        Checkout
      </Button>
    </Container>
  );
};

export default Subtotal;
