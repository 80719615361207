import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Navbar as BootstrapNavbar,
  Nav,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  clearTokensFromLocalStorage,
  getTokensFromLocalStorage,
} from "../Auth/tokenManagement";
import mamooLogo from "../../assets/images/logo.png";
import cognitoService from "../Auth/cognitoService";
import { jwtDecode } from "jwt-decode";
import { CiShoppingCart } from "react-icons/ci";
import "./Navbar.css";

const Navbar: React.FC = () => {
  const [username, setUsername] = useState<string | null>(null);
  const [userGroup, setUserGroup] = useState<string | null>(null);
  const [cartLength, setCartLength] = useState<number>(0);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedCartLength = localStorage.getItem("cartLength");
    if (storedCartLength) {
      setCartLength(parseInt(storedCartLength, 10));
    }
  }, []);

  useEffect(() => {
    const tokens = getTokensFromLocalStorage();
    if (tokens) {
      setUsername(tokens.username);
      const idToken = tokens.idToken;
      if (idToken) {
        try {
          const decodedToken: { "cognito:groups": string[] } =
            jwtDecode(idToken);
          if (decodedToken && decodedToken["cognito:groups"]) {
            setUserGroup(decodedToken["cognito:groups"][0]); // Assuming single group, adjust as needed
          } else {
            // Fetch user group from Cognito if not available in token
            cognitoService
              .getUserGroup()
              .then((group) => setUserGroup(group))
              .catch((err) => console.error("Error fetching user group:", err));
          }
        } catch (decodeError) {
          console.error("Error decoding token:", decodeError);
        }
      }
    } else {
      setUsername(null);
    }
  }, [location]);

  const handleLogout = async () => {
    try {
      const success = await cognitoService.logout();
      if (success) {
        clearTokensFromLocalStorage();
        setUsername(null);
        navigate("/");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <BootstrapNavbar
      variant="dark"
      expand="lg"
      style={{
        backgroundColor: "#B1D8B7",
        color: "#ADD8E6",
        paddingLeft: "20px",
        paddingRight: "20px",
        textAlign: "center",
      }}
    >
      <Nav className="navbar-brand">
        <img src={mamooLogo} width="50" height="50" alt="Mamoo logo" />
      </Nav>

      <Nav className="mr-auto">
        <Nav.Link href="#partners">
          <h5 className="link-text">Partners</h5>
        </Nav.Link>
      </Nav>

      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="d-flex justify-content-end"
      >
        {username ? (
          <Nav className="">
            <a
              className="cartIcon"
              href="/cart"
              style={{ position: "relative" }}
            >
              <CiShoppingCart size={35} color="black" />
              {/* Display cart length as a badge */}
              {cartLength > 0 && (
                <span className="cart-badge">{cartLength}</span>
              )}
            </a>
            <a className="orderLink" href="/myOrders">
              My Orders
            </a>
            <Dropdown>
              <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                Welcome {username}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {userGroup === "mamoo-users" ? (
                  <>
                    <Dropdown.Item href="/home">Home</Dropdown.Item>
                    <Dropdown.Item href="/user/profile">Profile</Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item href="/vendor/dashboard">
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item href="/user/profile?usertype=vendor">
                      Profile
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        ) : (
          <Nav className="">
            <Link to={"/user/login"}>
              <Button variant="outline-light" className="mr-2">
                Login
              </Button>
            </Link>
            <Link to={"/user/signup"}>
              <Button variant="outline-light" style={{ marginLeft: "8px" }}>
                Sign Up
              </Button>
            </Link>
          </Nav>
        )}
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
