function TopFrame() {
  return (
    <div className="d-flex flex-column my-3 mx-5">
      <h4>Plan Your Wedding</h4>
      <h5>Date:12/12/24</h5>
      <h5 className="m">Event Time: 4pm- 8pm</h5>
    </div>
  );
}

export default TopFrame;
