import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import { Container, Row } from "react-bootstrap";
import LoginForm from "../../Components/Login/Login";
import SignUpForm from "../../Components/Signup/Signup";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Box, Typography, Tab, Tabs } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import "./LoginPage.css";
import { useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { clearTokensFromLocalStorage } from "../../Components/Auth/tokenManagement";
const LoginPage: React.FC = () => {
  const { authTab } = useParams();
  console.log(authTab);
  const [tabValue, setTabValue] = React.useState(
    authTab ? authTab + "-tab" : "login-tab"
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: "center",
  }));
  useEffect(() => {
    clearTokensFromLocalStorage();
  }, []);

  return (
    <>
      <Container fluid className="main-container">
        <div className="row">
          <div className="col-md-6">
            <DemoPaper variant="elevation" style={{ alignItems: "center" }}>
              Insert Background Image
            </DemoPaper>
          </div>
          {/* <div className="col-md-6"> <LoginForm></LoginForm></div> */}
          <div className="col-md-6">
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Card className="w-100" style={{ backgroundColor: "white" }}>
                <CardContent className="justify-content-center container">
                  <Row className="title-row">
                    <Typography variant="h2"> Welcome to Mamoo </Typography>
                  </Row>
                  <Row>
                    <TabContext value={tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Login" value="login-tab" />
                          <Tab label="Sign Up" value="signup-tab" />
                        </TabList>
                      </Box>
                      <TabPanel value="login-tab">
                        <LoginForm></LoginForm>
                      </TabPanel>
                      <TabPanel value="signup-tab">
                        <SignUpForm />
                      </TabPanel>
                    </TabContext>
                  </Row>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default LoginPage;
