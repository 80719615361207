import React, { useState } from "react";
import axios from "axios";
import Navbar from "../../../Components/Navbar/Navbar";
import { storeIdInLocalStorage } from "../../../Components/Auth/tokenManagement";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepButton,
  Typography,
  Box,
  Button,
  Slide,
} from "@mui/material";
interface MenuItem {
  id: number;
  name: string;
  description: string;
  image: File | null;
  price: number;
  quantity: number;
}

const steps = ["Business details", "Business acounts", "Menu/Deliverables"];

const VendorRegistration: React.FC = () => {
  const [activeFormStep, setActiveFormStep] = React.useState(0);
  const [completedForm, setCompletedForm] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [transitionDirection, setTransitionDirection] = React.useState<
    "left" | "right"
  >("right");
  const [formData, setFormData] = useState({
    business_name: "",
    businessType: "",
    email: "",
    adress1: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    contact: "",
    logo: null as File | null,
    bank_name: "",
    account_number: "",
  });
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    { id: 1, name: "", description: "", image: null, price: 0, quantity: 0 },
  ]);
  const handleMenuItemChange = (
    index: number,
    field: keyof MenuItem,
    value: MenuItem[keyof MenuItem]
  ) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index] = { ...updatedMenuItems[index], [field]: value };
    setMenuItems(updatedMenuItems);
  };

  const addMenuItem = () => {
    setMenuItems([
      ...menuItems,
      {
        id: menuItems.length + 1,
        name: "",
        description: "",
        image: null,
        price: 0,
        quantity: 0,
      },
    ]);
  };

  const removeMenuItem = (index: number) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems.splice(index, 1);
    setMenuItems(updatedMenuItems);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completedForm).length;
  };

  const isLastStep = () => {
    return activeFormStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    setTransitionDirection("right");
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completedForm))
        : activeFormStep + 1;
    setActiveFormStep(newActiveStep);
  };

  const handleBack = () => {
    setTransitionDirection("left");
    setActiveFormStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveFormStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completedForm;
    newCompleted[activeFormStep] = true;
    setCompletedForm(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveFormStep(0);
    setCompletedForm({});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData({ ...formData, logo: file });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      console.log(formData);
      const response = await axios.post(
        "https://s5ew1kq35l.execute-api.us-west-2.amazonaws.com/Prod/Vendors",
        JSON.stringify({
          vendorName: formData.business_name,
          category: formData.businessType,
          address: {
            adress1: formData.adress1,
            street: formData.street,
            city: formData.city,
            state: formData.state,
            zipcode: formData.zip_code,
            country: formData.country,
          },
          contactNo: formData.contact,
          logo: formData.logo,
          bankDetails: {
            bankName: formData.bank_name,
            accountNo: formData.account_number,
          },
          emailId: formData.email,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data.statusCode === 201) {
        console.log("Registration successful!");
        console.log(JSON.parse(response.data.body));
        const userId = JSON.parse(response.data.body)["vendor_id"];
        storeIdInLocalStorage(userId, "vendor");
        navigate("/user/profile?usertype=vendor");
      } else {
        console.error("Registration failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const stepDetails: { [key: string]: JSX.Element } = {
    step1: (
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Business Name:</label>
            <input
              type="text"
              name="business_name"
              placeholder="Business name"
              value={formData.business_name}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Business Type:</label>
            <select
              name="businessType"
              value={formData.businessType}
              onChange={handleDropdownChange}
              className="form-select"
            >
              <option value="">Select</option>
              <option value="Type1">Type 1</option>
              <option value="Type2">Type 2</option>
              {/* Add more here */}
            </select>
          </div>
        </div>
        <h5 className="mb-2">Address:</h5>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Street Address:</label>
            <input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">City:</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">State:</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Zip Code:</label>
            <input
              type="text"
              name="zip_code"
              value={formData.zip_code}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Country:</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Contact:</label>
            <input
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Logo:</label>
            <input
              type="file"
              name="logo"
              onChange={handleLogoChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Email:</label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
    ),
    step2: (
      <div className="container">
        <h5 className="mb-2">Bank Account Details:</h5>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Bank Name:</label>
            <input
              type="text"
              name="bank_name"
              value={formData.bank_name}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Account Number:</label>
            <input
              type="text"
              name="account_number"
              value={formData.account_number}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
    ),
    step3: (
      <div className="container">
        <h5 className="mb-2">Menu Items</h5>
        {menuItems.map((item, index) => (
          <div key={item.id}>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label">Menu Item:</label>
                <input
                  type="text"
                  value={item.name}
                  onChange={(e) =>
                    handleMenuItemChange(index, "name", e.target.value)
                  }
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Description:</label>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) =>
                    handleMenuItemChange(index, "description", e.target.value)
                  }
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Image:</label>
                <input
                  type="file"
                  onChange={(e) =>
                    handleMenuItemChange(
                      index,
                      "image",
                      e.target.files ? e.target.files[0] : null
                    )
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label">Price:</label>
                <input
                  type="number"
                  value={item.price}
                  onChange={(e) =>
                    handleMenuItemChange(index, "price", Number(e.target.value))
                  }
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Quantity:</label>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) =>
                    handleMenuItemChange(
                      index,
                      "quantity",
                      Number(e.target.value)
                    )
                  }
                  className="form-control"
                />
              </div>
              <div className="col-md-3" style={{ marginTop: "auto" }}>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeMenuItem(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={addMenuItem}
        >
          Add More
        </button>
      </div>
    ),
  };

  return (
    <div>
      <Stepper
        style={{ maxWidth: "80%", margin: "auto" }}
        className="mt-3 mb-3"
        alternativeLabel
        activeStep={activeFormStep}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completedForm[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={handleSubmit}>
        {allStepsCompleted() ? (
          <div className="container-fluid">
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Typography>Do you wish to Submit?</Typography>

            <div className="row">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button color="error" onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </div>
        ) : (
          <Slide
            direction={transitionDirection}
            in={true}
            mountOnEnter
            unmountOnExit
            key={activeFormStep}
            timeout={600}
          >
            <div className="container-fluid">
              <div className="row" style={{ marginLeft: "2%" }}>
                <h2 className=" font-semibold mb-6">Register your business</h2>
              </div>

              {stepDetails["step" + (activeFormStep + 1)]}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeFormStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
                {activeFormStep !== steps.length &&
                  (completedForm[activeFormStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                    >
                      Step {activeFormStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))}
              </Box>
            </div>
          </Slide>
        )}
      </form>
    </div>
  );
};

export default VendorRegistration;
