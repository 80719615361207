import React, { useEffect, useState } from "react";
import axios from "axios";
import Subtotal from "../../../Components/Subtotal/Subtotal";
import cognitoService from "../../../Components/Auth/cognitoService";
import CartItemComponent from "../../../Components/Card/CardItem";
import PhotographerProfileCard from "../../../Components/Card/CardRight";

interface Item {
  priceId: string;
  quantity: number;
  name: string;
  primary_image_link: string;
  price: string;
}

const MyOrders: React.FC = () => {
  const [orders, setorders] = useState<Item[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchorders = async () => {
      try {
        const userId = await cognitoService.getUserIdFromSession();
        console.log(userId);
        const status = "order";
        if (!userId) {
          throw new Error("User ID not found. User may not be authenticated.");
        }
        // Replace with dynamic customer ID
        const response = await axios.get(
          `https://ni7q14skkl.execute-api.us-west-2.amazonaws.com/get-cart-data?userId=${userId}&status=${status}`
        );
        if (response.data.cartItems.length > 0) {
          console.log(response.data.cartItems[0].Items);
          setorders(response.data.cartItems[0].Items);
        }
      } catch (error) {
        setError("Error fetching orders data");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchorders();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!orders) return <p>No Items Ordered</p>;

  return (
    <div>
      <h1>orders so far</h1>

      <h3>Items:</h3>
      <li>
        {orders.length > 0 ? (
          orders.map((item) => {
            const data = {
              name: item.name,
              image: item.primary_image_link,
              location: String(item.quantity),
              bio: item.price,
            };
            return (
              <>
                <PhotographerProfileCard photographer={data} />
                {/* <CartItemComponent item={data} /> */}
              </>
            );
          })
        ) : (
          <li>No items in the orders</li>
        )}
      </li>
    </div>
  );
};

export default MyOrders;
