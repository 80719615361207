import React from "react";
import Footer from "../../Components/Footer/Footer";
import EventCard from "../../Components/Card/Event";
import { Container, Row } from "react-bootstrap";
import Slideshow from "../../Components/SlideShow/SlideShowSlide";
import eventImage from "../../assets/images/events.jpg";
import communityImage from "../../assets/images/community.jpg";
import carPoolKidsImage from "../../assets/images/carpoolkids.jpg";
import servicesImage from "../../assets/images/services.jpg";
import meetupImage from "../../assets/images/meetup.jpg";
import ticketsImage from "../../assets/images/tickets.jpg";
import Navbar from "../../Components/Navbar/Navbar";

const Home: React.FC = () => {
  return (
    <>
      <Container>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          <EventCard
            title="Plan Your Event!!"
            description="Stress free event planning with us"
            details="Join us for a day filled with fun and surprises as we celebrate a special birthday."
            imageUrl={eventImage}
            linkTo="/events"
          />
          <EventCard
            title="Know Your Community"
            description="Subscribe to your community events you like!!"
            details="Witness the love and commitment as two souls come together in matrimony."
            imageUrl={communityImage} // Replace with your image URL
            linkTo="/communities"
          />
          <EventCard
            title="Car Pool for Kids"
            description="Celebrate someone's birthday with joy and laughter."
            details="Join us for a day filled with fun and surprises as we celebrate a special birthday."
            imageUrl={carPoolKidsImage} // Replace with your image URL
            linkTo="#"
          />
          <EventCard
            title="Professional services"
            description="Join us in celebrating the union of two beautiful souls."
            details="Witness the love and commitment as two souls come together in matrimony."
            imageUrl={servicesImage} // Replace with your image URL
            linkTo="#"
          />
          <EventCard
            title="Meet ups"
            description="Meet new people from your community"
            details="Witness the love and commitment as two souls come together in matrimony."
            imageUrl={meetupImage} // Replace with your image URL
            linkTo="#"
          />
          <EventCard
            title="Tickets for Events"
            description="Buy Tickets for your favourite events"
            details="Yayy all the way!!"
            imageUrl={ticketsImage} // Replace with your image URL
            linkTo="#"
          />
        </Row>
        {/* <Slideshow slides={slides} /> */}
      </Container>
    </>
  );
};

export default Home;
