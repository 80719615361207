import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

interface CartItem {
  id: string;
  name: string;
  imageUrl: string;
  quantity: number;
  price: string;
}

interface CartItemProps {
  item: CartItem;
}

const CartItemComponent: React.FC<CartItemProps> = ({ item }) => {
  return (
    <Card
      style={{ width: "30rem", marginTop: "3rem" }}
      className="d-flex flex-row"
    >
      <Card.Img
        variant="top"
        src={item.imageUrl}
        alt={item.name}
        style={{ width: "12em", objectFit: "cover" }}
      />
      <Card.Body style={{ marginLeft: "20px", flexGrow: 1 }}>
        <Card.Title>{item.name}</Card.Title>
        {/* <Card.Text>{item.description}</Card.Text> */}
        {/* <Card.Text>Quantity: {item.quantity}</Card.Text> */}
        <Card.Text>Price: ${item.price}</Card.Text>
        <Button variant="primary">Increase Quantity</Button>
        <Button variant="danger">Delete Item</Button>
      </Card.Body>
    </Card>
  );
};

export default CartItemComponent;
