import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Events from "./Pages/Events/Events";
import LoginPage from "./Pages/Login/LoginPage";
import VendorDashboard from "./Pages/Vendor/VendorDashboard/VendorDashboard";
import Profiles from "./Pages/Profiles/Profiles";
import VendorRegistration from "./Pages/Vendor/VendorRegistration/VendorRegistration";
import Plan from "./Pages/PlanEvent/CategoriesPage/CategoriesPage";
import Info from "./Pages/PlanEvent/ProductsPage/ProductsPage";
import IndividualDetails from "./Pages/PlanEvent/IndividualDetails/IndividualDetails";
import EventPage from "./Pages/EventPage/EventPage";
// import Subtotal from "./Components/Subtotal/Subtotal";
import communitiesData from "./Pages/CommunityPage/communities";
import CommunityPage from "./Pages/CommunityPage/CommunityPage";
import SuccessPage from "./Pages/SuccessPage/SuccessPage";
import Cart from "./Pages/PlanEvent/Cart/Cart";
import Subtotal from "./Components/Subtotal/Subtotal";
import CreateProduct from "./Pages/Vendor/CreateProduct/CreateProduct";
import MyOrders from "./Pages/PlanEvent/MyOrders/MyOrders";
import WelcomePage from "./Pages/WelcomePage/WelcomePage";

const App: React.FC = () => {
  const [cartLength, setCartLength] = useState<number>(0);

  const cartItems = [
    {
      price_id: "price_1Of5arLdDG2Sy7GC8s3eRghl",
      name: "Item 1",
      description: "Description for Item 1",
      imageUrl:
        "https://tse4.mm.bing.net/th?id=OIP.4wc4c5G19PaadYBKbqvcnAHaE7&pid=Api&P=0&h=220",
      quantity: 1,
      price: 10,
    },
    // {
    //   id: "price_1Of5arLdDG2Sy7GC8s3eRghl",
    //   name: "Item 2",
    //   description: "Description for Item 2",
    //   imageUrl: "https://via.placeholder.com/150",
    //   quantity: 1,
    //   price: 20,
    // },
  ];

  const photographers = [
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
      linkTo: "/",
    },
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
    },
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
    },
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
    },
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
    },
    {
      name: "John Doe",
      location: "New York, USA",
      bio: "Passionate photographer capturing moments in time.",
      image:
        "https://tse3.mm.bing.net/th?id=OIP.nteqqhP-kjupFUnqA9o8SgHaE7&pid=Api&P=0&h=220",
    },
  ];
  const activities = [
    {
      name: "Bungee Jumping",
      bio: "",
      location: "Kawarau bridge",
      image: "https://c1.staticflickr.com/9/8239/8474358434_9bda922228_b.jpg",
    },
    {
      name: "Bungee Jumping",
      bio: "",
      location: "Kawarau bridge",
      image: "https://c1.staticflickr.com/9/8239/8474358434_9bda922228_b.jpg",
    },
    {
      name: "Bungee Jumping",
      bio: "",
      location: "Kawarau bridge",
      image: "https://c1.staticflickr.com/9/8239/8474358434_9bda922228_b.jpg",
    },
    {
      name: "Bungee Jumping",
      bio: "",
      location: "Kawarau bridge",
      image: "https://c1.staticflickr.com/9/8239/8474358434_9bda922228_b.jpg",
    },
    {
      name: "Bungee Jumping",
      bio: "",
      location: "Kawarau bridge",
      image: "https://c1.staticflickr.com/9/8239/8474358434_9bda922228_b.jpg",
    },
  ];
  const dataPhotographers = photographers.map((photographer) => ({
    ...photographer,
    // linkTo: `/${encodeURIComponent(photographer.name.replace(/\s+/g, ""))}`,
    linkTo: "eachItem",
  }));

  const dataActivities = activities.map((activity) => ({
    ...activity,
    // linkTo: `/${encodeURIComponent(activity.name.replace(/\s+/g, ""))}`,
    linkTo: "eachItem",
  }));

  const details = {
    name: "Name",
    cost: "150$ per person",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, cum officiis reprehenderit praesentium iure nostrum. Voluptatem iusto maxime, aperiam ut iste nisi. A, repellendus minima! Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, voluptatum excepturi. Atque beatae voluptatem est excepturi similique, dolores minus obcaecati corrupti facere ea tempora fugiat ratione a libero accusantium magnam nemo odit perspiciatis magni doloremque hic repellat dolorem! Distinctio quas ducimus laudantium rem eos optio?",
  };
  const [communities, setCommunities] = useState<any[]>([...communitiesData]);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate replace to="/user/login" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/user/:authTab" element={<LoginPage />} />
        <Route path="/user/profile" element={<Profiles />} />
        <Route path="/events" element={<Events />} />
        <Route
          path="/communities"
          element={<CommunityPage data={communities} rowsPerPage={4} />}
        />
        <Route path="/plan" element={<Plan />} />
        <Route
          path="/Photographers"
          element={<Info setCartLength={setCartLength} />}
        />
        <Route
          path="/Restaurants"
          element={<Info setCartLength={setCartLength} />}
        />
        <Route
          path="/Activities"
          element={<Info setCartLength={setCartLength} />}
        />
        <Route
          path="/Activities/eachItem"
          element={<IndividualDetails details={details} />}
        />
        <Route path="/WelcomePage" element={<WelcomePage />} />
        <Route path="/myOrders" element={<MyOrders />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/partners" element={<EventPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/vendor/dashboard" element={<VendorDashboard />} />
        <Route path="/vendor/register" element={<VendorRegistration />} />
        <Route path="/vendor/createProduct" element={<CreateProduct />} />
        {/* <Route path="/partnerRegistrationDetails" element={<PartnerRegistrationDetails/>} /> */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
