import React, { ChangeEventHandler, useState } from "react";
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { TextField, Button as MButton, Typography, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, } from "@mui/material";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AWS from 'aws-sdk';

// TO:DO
// wrong password
// adding spaced names in username


interface SignUpFormProps {
  //   onSignUpSuccess: () => void;
}
const accessKeyId: string = process.env.REACT_APP_AWS_ACCESS_KEY_ID!;
const secretAccessKey: string = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;
const credentials = new AWS.Credentials({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey
});
AWS.config.credentials = credentials
AWS.config.update({ region: 'us-west-2' });


const SignUpForm: React.FC<SignUpFormProps> = ({ }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [registered, setRegistered] = useState(false);
  const [userType, setUserType] = useState<string | any>("users");
  const navigate = useNavigate()
  const listOfUsers = {
    "users": "mamoo-users",
    "partners": "mamoo-partners",
    "vendors": "mamoo-partners"
  }
  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
  };

  const userPool = new CognitoUserPool(poolData);

  const handleUserTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUserType(event.target.value);
  };


  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];

    try {
      await new Promise((resolve, reject) => {
        userPool.signUp(username, password, attributeList, [], (err, result) => {
          if (err) {
            reject(err);
          } else {
            setRegistered(true);
            resolve(result);
          }
        });
      });
    } catch (error) {
      setErrorMessage('Sign-up failed. Please try again.');
      console.error('Error:', error);
    }
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
      Username: username,
      GroupName: userType
    };

    try {
      await cognitoIdentityServiceProvider.adminAddUserToGroup(params).promise();
      console.log('User added to group successfully.');
      alert("DONE")
    } catch (error) {
      console.error('Error adding user to group:', error);
      throw error;
    }
  };

  const handleVerification = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await new Promise((resolve, reject) => {
        const userData = {
          Username: username,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verificationCode, true, (err: Error | null, result: any) => {
          if (err) {
            reject(err);
          } else {
            alert('User confirmed: ' + result);
            // If verification succeeds, you can redirect the user to a login page or display a success message
            resolve(result);
            navigate("/user/login")
          }
        });
      });
    } catch (error) {
      setErrorMessage('Verification failed. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="container" style={{ justifyContent: "center", height: "100%", width: "100%" }}>
      <Row>
        <Typography variant="h5" style={{ textAlign: "center" }}>Sign Up</Typography>
      </Row>
      <Row>
        {!registered ? (
          <form onSubmit={handleSignUp}>
            <Form.Group controlId="userType">
              <Form.Label>Category</Form.Label>
              <Form.Select value={userType} onChange={handleUserTypeChange}>
                <option value="">Select user type</option>
                <option value="mamoo-users">Users</option>
                <option value="mamoo-partners">Partners</option>
                <option value="mamoo-partners">Vendors</option>
              </Form.Select>
            </Form.Group>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px', // Adjust the value to change the roundness
                  // Adjust the color and width of the border
                },
              }}
              type="string"
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px', // Adjust the value to change the roundness
                  // Adjust the color and width of the border
                },
              }}
              type="string"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <OverlayTrigger
              key='top'
              placement='top'
              overlay={<Tooltip id={`tooltip-password`}>Password should contain: letters, numbers, symbols</Tooltip>}
            >
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px', // Adjust the value to change the roundness
                    // Adjust the color and width of the border
                  },
                }}
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
                margin="normal"
              />
            </OverlayTrigger>
            <MButton type="submit" variant="contained" color="primary" fullWidth>
              Sign Up
            </MButton>
          </form>
        ) : (
          <form onSubmit={handleVerification}>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px', // Adjust the value to change the roundness
                  // Adjust the color and width of the border
                },
              }}
              type="text"
              label="Verification Code"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <MButton type="submit" variant="contained" color="primary" fullWidth>
              Verify
            </MButton>
          </form>
        )}
      </Row>
      {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
    </div>
  );
};

export default SignUpForm;
