import React, { useState } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js"; // Import CognitoUserSession if it's not already imported

export const getCognitoGroupsFromSession = (
  session: CognitoUserSession
): string[] | undefined => {
  const idTokenPayload = session.getIdToken().payload;
  return idTokenPayload["cognito:groups"];
};

export const storeTokensInLocalStorage = (tokens: {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  username: string;
}) => {
  localStorage.setItem("accessToken", tokens.accessToken);
  localStorage.setItem("idToken", tokens.idToken);
  localStorage.setItem("refreshToken", tokens.refreshToken);
  localStorage.setItem("username", tokens.username);
};

export const storeIdInLocalStorage = (Id: string, userType: string) => {
  localStorage.setItem("userId", Id);
  localStorage.setItem("userType", userType);
};

export const getTokensFromLocalStorage = (): {
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
  username: string | null;
} => {
  const accessToken = localStorage.getItem("accessToken");
  const idToken = localStorage.getItem("idToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const username = localStorage.getItem("username");
  if (
    accessToken === null ||
    idToken === null ||
    refreshToken === null ||
    username === null
  ) {
    return {
      accessToken: null,
      idToken: null,
      refreshToken: null,
      username: null,
    };
  }
  return { accessToken, idToken, refreshToken, username };
};

export const getIdFromLocalStorage = (): {
  userId: string | null;
  userType: string | null;
} => {
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  if (userId === null || userType === null) {
    return { userId: null, userType: null };
  }
  return { userId, userType };
};

export const clearTokensFromLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("username");
  localStorage.removeItem("userId");
  localStorage.removeItem("userType");
};
