import React,{ useState } from "react";
import Slideshow from "../../Components/SlideShow/SlideShowSlide";
import { Footer } from "react-bootstrap/lib/Modal";
import Events from "../Events/Events";
import EventPage from "../EventPage/EventPage";
import communityImage from "../../assets/images/community.jpg";
import useTable from "./UseTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import "./CommunityPage.css";
import RegisterService from "../../Services/Register/Register.Service";
import IRegisterData from "../../Services/Types/Register.Type";

const slides = [
  {
    url: "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  },
];

  

  interface TableProps {
    data: {
      id: number;
      event:      string;
      description: string;
      organization: string;
    }[];
    rowsPerPage: number;
  }

  type Registration = {
    currentRegistration: IRegisterData;
    message: string;
  }

  const handleVolunteerClick = () => {
    console.log("Volunteer button clicked");
  };

  const handleRegisterClick = (data: IRegisterData) => {
    RegisterService.update(data, data.id)
    
    console.log("Registration button clicked");
  };
  
  const CommunityPage: React.FC<TableProps> = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState<number>(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    return (
    <>
       <Slideshow slides={slides} />
       <div className="divMargin">
       <table className={styles.table}>
       <h5 className="hxMargin">Community Events!</h5>
        </table>
        </div>
      <div className="{styles.table-wrapper}" >
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Event</th>
            <th className={styles.tableHeader}>Description</th>
            <th className={styles.tableHeader}>Organization</th>
            <th className={styles.tableHeader}></th>
            <th className={styles.tableHeader}></th>

          </tr>
        </thead>
        <tbody>
        {slice.map((communityEvent: { event: string, description: string, organization: string })  => {
                return(
              <tr>
                  <td>{communityEvent.event}</td>
                  <td>{communityEvent.description}</td> 
                  <td>{communityEvent.organization}</td> 
                  <td><button className="btn btn-outline-secondary" onClick={() => handleVolunteerClick()}>Register</button></td>
                  <td><button className="btn btn-outline-secondary" onClick={() => handleVolunteerClick()}>Volunteer</button></td>
              </tr>
              )
            })}
        </tbody>
      </table>
    </div> 
    <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    
    </>
    
  );
};

export default CommunityPage;
