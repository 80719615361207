import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

const WelcomePage: React.FC = () => {
  const [event, setEvent] = useState("");
  const [guests, setGuests] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Store form data in localStorage
    const eventData = { event, guests, eventDate, phoneNumber };
    localStorage.setItem("eventDetails", JSON.stringify(eventData));

    alert("Event details saved. You can make payment now.");
    navigate("/plan");
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex align-items-center justify-content-center"
    >
      <Row className="w-100">
        <Col md={6}>
          <h1 className="mb-4">Plan Your Event</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEvent">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the event"
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGuests">
              <Form.Label>Number of Guests</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of guests"
                value={guests}
                onChange={(e) => setGuests(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEventDate">
              <Form.Label>Event Date</Form.Label>
              <Form.Control
                type="date"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Save Details
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomePage;
