import React from "react";
import ProductForm from "../../../Components/ProductForm/ProductForm";

function CreateProduct() {
  return (
    <div>
      <ProductForm />
    </div>
  );
}

export default CreateProduct;
