import http from "../http-common";
import IRegisterData from "../Types/Register.Type"

class RegisterService {
  getAll() {
    return http.get<Array<IRegisterData>>("/registrations");
  }

  get(id: string) {
    return http.get<IRegisterData>(`/registrations/${id}`);
  }

  create(data: IRegisterData) {
    return http.post<IRegisterData>("/registrations", data);
  }

  update(data: IRegisterData, id: any) {
    return http.put<any>(`/registrations/${id}`, data);
  }

  delete(id: any) {
    return http.delete<any>(`/registrations/${id}`);
  }

  deleteAll() {
    return http.delete<any>(`/registrations`);
  }

  findByOrganization(organization: string) {
    return http.get<Array<IRegisterData>>(`/registrations?organization=${organization}`);
  }
}

export default new RegisterService();