import {
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";

const userPoolId = <string>process.env.REACT_APP_COGNITO_USER_POOL_ID;
const clientId = <string>process.env.REACT_APP_COGNITO_CLIENT_ID;
const region = <string>process.env.REACT_APP_AUTH_REGION;

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
});

const cognitoService = {
  logout: async () => {
    const cognitoUser = getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
      return true;
    }

    return false;
  },

  getUserGroup: async (): Promise<string | null> => {
    const user = userPool.getCurrentUser();
    if (!user) return null;

    return new Promise((resolve, reject) => {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err || !session) {
            reject(err);
          } else {
            const idToken = session.getIdToken().getJwtToken();
            const decodedToken = jwtDecode<{ "cognito:groups": string[] }>(
              idToken
            );
            if (decodedToken && decodedToken["cognito:groups"]) {
              resolve(decodedToken["cognito:groups"][0]); // assuming single grp
            } else {
              resolve(null);
            }
          }
        }
      );
    });
  },
  getUserIdFromSession: async (): Promise<string | null> => {
    const user = getCurrentUser();
    if (!user) return null;

    return new Promise((resolve, reject) => {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err || !session) {
            reject(err);
          } else {
            const idToken = session.getIdToken().getJwtToken();
            const decodedToken = jwtDecode<{ sub: string }>(idToken);
            resolve(decodedToken.sub);
          }
        }
      );
    });
  },
};

const getCurrentUser = () => {
  const userPool = getUserPool();
  return userPool.getCurrentUser();
};

const getUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: clientId,
  });
};

export default cognitoService;
