import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Navbar from "../../Components/Navbar/Navbar";
import {
  getIdFromLocalStorage,
  getTokensFromLocalStorage,
} from "../../Components/Auth/tokenManagement";
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string | null) {
  if (name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  return null;
}

const cardStyle = {
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "20px",
  textAlign: "center" as const,
  maxWidth: "300px",
  margin: "auto",
};

const logoStyle = {
  height: "60px",
  width: "60px",
  margin: "auto",
  background: "#007bff", // React Bootstrap primary color
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: "24px",
  marginBottom: "10px",
};

const VendorProfile: React.FC = () => {
  const [vendorData, setVendorData] = useState<any>(null);
  const navigate = useNavigate();
  const userParams = getTokensFromLocalStorage();
  const user = getIdFromLocalStorage();
  const getAllUserData = async (vendorId: string | null) => {
    if (vendorId) {
      try {
        const resp = await axios.get(
          "https://s5ew1kq35l.execute-api.us-west-2.amazonaws.com/Prod/Vendors",
          { params: { vendorId: vendorId } }
        );
        console.log(resp.data["vendors"][0]);
        setVendorData(() => {
          return resp.data["vendors"][0];
        }); // Set the vendor data in state
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    }
  };
  useEffect(() => {
    console.log(user);
    getAllUserData(user.userId);
    console.log("VEF", vendorData);
  }, []);
  return (
    <div>
      <Container fluid>
        <h4>My Profile</h4>
        <hr></hr>
        <div className="row">
          <div className="col-md-auto ml-5" style={{ textAlign: "right" }}>
            <Avatar
              {...stringAvatar(userParams.username)}
              sx={{
                width: 100,
                height: 100,
                fontSize: "xxx-large",
                padding: 0,
              }}
            />
          </div>
          <div className="col-md-auto">
            <h4>Welcome, {userParams.username}</h4>
          </div>
        </div>
        {/* <Card sx={{maxWidth:"100%"}}>
        <CardHeader avatar={
          <Avatar sx={{ bgcolor: "blue"}} aria-label="recipe">
            {userParams.username?.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={userParams.username}
        subheader=""
        >

        </CardHeader>
        {userParams.username}
      </Card> */}
        <div style={{ width: "80%", textAlign: "center", margin: "auto" }}>
          <hr></hr>
        </div>
        {vendorData ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4" gutterBottom>
                Vendor Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom>
                Business Name: {vendorData.vendorName}
                <br />
                Address:{" "}
                {`${vendorData.address.street}, ${vendorData.address.city}, ${vendorData.address.state}, ${vendorData.address.zipcode}`}
                <br />
                Contact: {vendorData.contactInfo}
                <br />
                Email: {vendorData.emailId}
                <br />
                {/* Bank: {`${vendorData.bankDetails.bankName} - Account No: ${vendorData.bankDetails.accountNo}`} */}
              </Typography>
              <Link to={"/vendor/update"}>
                <Button variant="primary" style={{ marginTop: "1rem" }}>
                  <Typography variant="button">Update Details</Typography>
                </Button>
              </Link>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div>
            <p>Vendor details...</p>
            <Link to={"/vendor/register"}>
              <Button variant="primary">Add Details</Button>
            </Link>
          </div>
        )}
      </Container>
    </div>
  );
};

export default VendorProfile;
