import React, { useEffect, useState } from "react";
import axios from "axios";
// import * as yaml from "js-yaml";

interface Vendor {
  eventId: number;
  vendorsInvolved: string[];
  name: string;
  capacity: string;
  category: string;
  eventOwner: string;
  location: string;
}

const EventPage: React.FC = () => {
  const [data, setData] = useState<Vendor[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://5jqrh2ryv4.execute-api.us-west-2.amazonaws.com/prod/events",
          {
            headers: {
              "Content-Type": "application/json",
            }, // Allow requests from any origin (replace with your specific origin if needed)
          }
        );
        let data = response.data.body;
        // const parsedData = yaml.load(data) as Vendor[];

        setData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleRegisterClick = () => {
    console.log("Register button clicked");
  };

  const handleVolunteerClick = () => {
    console.log("Volunteer button clicked");
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Event</th>
          <th>Date</th>
          <th>Details</th>
          <th>Register</th>
          <th>Volunteer</th>
        </tr>
      </thead>
      {/* {data.map((u) => (
        <h2 key={u.eventId}>u.eventId</h2>
      ))} */}
      <tbody>
        {data.map((user) => (
          <tr key={user.eventId}>
            <td>{user.eventId}</td>
            <td>{user.vendorsInvolved}</td>
            <td>{user.location}</td>
            <td>{user.category}</td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => handleRegisterClick()}
              >
                Register
              </button>
            </td>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => handleVolunteerClick()}
              >
                volunteer
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EventPage;
