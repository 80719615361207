import React from "react";
import {
  Navbar as BootstrapNavbar,
  Nav,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import Calendar from "../Calendar/Calendar";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function Filter() {
  return (
    <BootstrapNavbar
      variant="dark"
      expand="lg"
      className="d-flex justify-content-between align-items-center"
      style={{
        backgroundColor: "#ffffff",
        color: "#ADD8E6",
        paddingLeft: "20px",
        paddingRight: "20px",
        textAlign: "center",
      }}
    >
      <BootstrapNavbar.Collapse id="basic-navbar-nav" className="d-flex">
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      </LocalizationProvider>
        <DatePicker className="p-2 w-3" /> */}
        <DropdownButton id="dropdown-basic-button" title="Sort By">
          <Dropdown.Item href="#/action-1">Price: Low to high</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Price: High to Low</Dropdown.Item>
        </DropdownButton>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
}

export default Filter;
