import React from 'react';
import { Button } from 'react-bootstrap';

const UserProfile: React.FC = () => {
  return (
    <div>
      <h4>User Profile</h4>
      <p>User details...</p>
      <Button variant="primary">Add Details</Button>
    </div>
  );
};

export default UserProfile;
