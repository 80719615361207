import axios from "axios";
import TopFrame from "../../../Components/TopFrame/TopFrame";
import { Navigate, useNavigate } from "react-router-dom";
import cognitoService from "../../../Components/Auth/cognitoService";

interface Details {
  name: string;
  cost: string;
  description: string;
}
interface IndividualDetailsProps {
  details: Details;
}

interface CartItem {
  itemId: string;
  quantity: number;
}

interface AddToCartPayload {
  userID: string;
  Items: CartItem[];
  status: string;
}

function IndividualDetails({ details }: IndividualDetailsProps) {
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const userId = await cognitoService.getUserIdFromSession();
    const status = "cart";
    console.log(userId);
    if (!userId) {
      throw new Error("User ID not found. User may not be authenticated.");
    }

    if (!userId) {
      // Handle case where customer ID is not available
      alert("Customer ID is missing");
      return;
    }

    const items = [
      {
        //change later dynamically *******
        itemId: "price_1PgZsBLdDG2Sy7GCQYPz9Bwr",
        quantity: 1,
      },
    ];

    const payload: AddToCartPayload = {
      userID: userId,
      Items: items,
      status: status,
    };
    console.log("status is ", status);
    const apiUrl =
      "https://ni7q14skkl.execute-api.us-west-2.amazonaws.com/demo/create-cart-data";

    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Cart updated successfully:", response.data);
      navigate("/cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  return (
    <>
      <TopFrame />
      <div className="d-flex flex-row m-3">
        <div
          className="d-flex flex-column"
          style={{ width: "15em", objectFit: "contain" }}
        >
          <img
            src="https://2.bp.blogspot.com/-Dh-mbOCasxs/T3qB047sunI/AAAAAAAAAEo/q8ulNpv30zE/s1600/DSCF2943.JPG"
            className="m-2"
          />
          <img
            src="https://2.bp.blogspot.com/-Dh-mbOCasxs/T3qB047sunI/AAAAAAAAAEo/q8ulNpv30zE/s1600/DSCF2943.JPG"
            className="m-2"
          />
          <img
            src="https://2.bp.blogspot.com/-Dh-mbOCasxs/T3qB047sunI/AAAAAAAAAEo/q8ulNpv30zE/s1600/DSCF2943.JPG"
            className="m-2"
          />
        </div>
        <div className="d-flex flex-column m-3">
          <form onSubmit={handleSubmit}>
            <h4>{details.name}</h4>
            <h5>{details.cost}</h5>
            <h5>{details.description}</h5>
            <button type="submit">Add to Cart</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default IndividualDetails;
