import React from "react";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

interface Photographer {
  name: string;
  location: string;
  bio?: string;
  image: string;
  // linkTo: string;
}

interface PhotographerProfileCardProps {
  photographer: Photographer;
}

const PhotographerProfileCard: React.FC<PhotographerProfileCardProps> = ({
  photographer,
}) => {
  const navigate = useNavigate();
  return (
    <div
    // onClick={() => navigate(photographer.linkTo)}
    // style={{
    //   cursor: "pointer",
    //   transition: "transform 0.3s ease-in-out",
    // }}
    // onMouseOver={(e) => {
    //   e.currentTarget.style.transform = "scale(1.02)";
    // }}
    // onMouseOut={(e) => {
    //   e.currentTarget.style.transform = "scale(1)";
    // }}
    >
      <Card
        style={{
          maxWidth: 800,
          margin: "10px 10em",
          alignItems: "center",
        }}
        className="d-flex flex-row"
      >
        <Image
          src={photographer.image}
          alt={`${photographer.name}'s profile`}
          style={{ width: "12em", objectFit: "cover" }}
        />
        <Card.Body style={{ marginLeft: "20px", flexGrow: 1 }}>
          <Card.Title>{photographer.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {photographer.location}
          </Card.Subtitle>
          <Card.Text>{photographer.bio ? photographer.bio : ""}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PhotographerProfileCard;
