import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { CardHeader, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  getCognitoGroupsFromSession,
  storeTokensInLocalStorage,
} from "../Auth/tokenManagement";

interface LoginFormProps {
  //   onLoginSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
  };
  const userPool = new CognitoUserPool(poolData);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    try {
      await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (session) => {
            alert("Successfully logged in");
            console.log(session);
            resolve(session);
            const accessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();
            const username = session.getIdToken().payload["cognito:username"];
            storeTokensInLocalStorage({
              accessToken,
              idToken,
              refreshToken,
              username,
            });
            const cognitoGroups = getCognitoGroupsFromSession(session);
            console.log(cognitoGroups, "CG");
            if (cognitoGroups && cognitoGroups.length > 0) {
              console.log(
                cognitoGroups[0],
                "hello......................................."
              );
              if (cognitoGroups[0] === "mamoo-users") {
                navigate("/home");
              } else if (cognitoGroups[0] === "mamoo-partners") {
                navigate("/vendor/dashboard");
              }
            }
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });

      // If authentication is successful, call the onLoginSuccess callback
      //   onLoginSuccess();
    } catch (error) {
      setErrorMessage("Login failed. Please check your credentials.");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="container"
      style={{ justifyContent: "center", height: "100%", width: "100%" }}
    >
      <Row>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Login
        </Typography>
      </Row>
      <Row>
        <form onSubmit={handleLogin}>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px", // Adjust the value to change the roundness
                // Adjust the color and width of the border
              },
            }}
            type="string"
            label="Username"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-password`}>
                Password should contain: letters, numbers, symbols
              </Tooltip>
            }
          >
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px", // Adjust the value to change the roundness
                  // Adjust the color and width of the border
                },
              }}
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </OverlayTrigger>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Row>

      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default LoginForm;
