import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import axios from "axios";
import {
  getTokensFromLocalStorage,
  getIdFromLocalStorage,
} from "../../../Components/Auth/tokenManagement";
import "./VendorDashboard.css"; // Import custom CSS for styling

const VendorDashboard: React.FC = () => {
  const [username, setUsername] = useState<string | null>(
    getTokensFromLocalStorage().username
  );
  const [events, setEvents] = useState<any[]>([]);
  const user = getIdFromLocalStorage();

  const getVendorEvents = async () => {
    try {
      const vendorId = "c5790e31-399a-4411-9e98-d027772da065"; // Example vendor ID
      const url =
        "https://1ewcgsb5wb.execute-api.us-west-2.amazonaws.com/get-vendor-orders?vendorId=" +
        vendorId;
      const response = await axios.get(url, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
      console.log(response);
      const eventData = response.data.VendorOrders;
      setEvents(eventData); // Set the vendor orders into the events state
    } catch (error) {
      console.error("Error fetching vendor events:", error);
    }
  };

  useEffect(() => {
    getVendorEvents();
  }, []);

  const handleFinishClick = (vendorId: string, userId: string) => {
    // Handle logic when Finish button is clicked (e.g., mark as complete, send request)
    console.log(`Finished order for vendorId: ${vendorId}, userId: ${userId}`);
  };

  return (
    <Container fluid className="p-4">
      <Row className="justify-content-center mb-4">
        <Col md={10}>
          <h2 className="dashboard-title">Welcome, {username}</h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={10}>
          {/* Events Table */}
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>Order Items</th>
                <th>Phone Number</th>
                <th>Event Date</th>
                <th>Event Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr key={index}>
                  <td>
                    {event.orderItems.length > 0 ? (
                      <ul className="order-items-list">
                        {event.orderItems.map((item: any) => (
                          <li key={item.priceId} className="order-item">
                            <div className="item-detail">
                              <strong>Item:</strong> {item.name}
                            </div>
                            <div className="item-detail">
                              <strong>Quantity:</strong> {item.quantity}
                            </div>
                            <div className="item-detail">
                              <strong>Price:</strong> ${item.price}
                            </div>
                            <div className="item-image">
                              <img
                                src={item.primary_image_link}
                                alt={item.name}
                                className="item-img"
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No items found</p>
                    )}
                  </td>
                  <td>
                    {event.eventDetails.phoneNumber ||
                      "No phone number provided"}
                  </td>
                  <td>
                    {event.eventDetails.eventDate || "No event date provided"}
                  </td>
                  <td>
                    <div>
                      <strong>Event Name:</strong>{" "}
                      {event.eventDetails.event || "No event name provided"}
                    </div>
                    <div>
                      <strong>Guests:</strong>{" "}
                      {event.eventDetails.guests || "No guest count provided"}
                    </div>
                  </td>
                  <td>
                    <Button
                      variant="success"
                      className="finish-button"
                      onClick={() =>
                        handleFinishClick(event.vendorId, event.userId)
                      }
                    >
                      Finish
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default VendorDashboard;
