export default  [
    {
      "id": 1,
      url: "https://wataweb.org/events.aspx",
      "event":"Winter clothes drive ",
    "description":"Give clothes away to homeless",
    "organization": "WATS"
    },
    {
      "id": 2,
      url: "https://wataweb.org/events.aspx",
      "event":"Womens night",
      "description":"Party time",
      "organization": "ATA"
    },
    {
      "id": 3,
      url: "https://wataweb.org/events.aspx",
      "event":"Annual event",
      "description":"Convention",
      "organization": "TTA"
    },    {
      "id": 4,
      url: "https://wataweb.org/events.aspx",
      "event":"Winter clothes drive ",
    "description":"Give clothes away to homeless",
    "organization": "WATS"
    },
    {
      "id": 5,
      url: "https://wataweb.org/events.aspx",
      "event":"Womens night",
      "description":"Party time",
      "organization": "ATA"
    },
    {
      "id": 6,
      url: "https://wataweb.org/events.aspx",
      "event":"Annual event",
      "description":"Convention",
      "organization": "TTA"
    }
  ];