import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card } from "react-bootstrap";
import Filter from "../../../Components/Filter/Filter";
import cognitoService from "../../../Components/Auth/cognitoService";

interface DataItems {
  name: string;
  price: string;
  primary_image_link: string;
  PK: string;
  price_id: string;
  vendorId: string;
}

interface CartItem {
  priceId: string;
  quantity: number;
  name: string;
  price: string;
  primary_image_link: string;
  vendorId: string;
}

interface AddToCartPayload {
  userID: string;
  status: string;
  Items: CartItem[];
  transactionId: string;
}

const generateUniqueTransactionId = (status: string): string => {
  return `${status}_txn_${Math.random().toString(36).substr(2, 9)}`;
};

interface CartProps {
  setCartLength: React.Dispatch<React.SetStateAction<number>>;
}

const extractVendorId = (fullId: string) => {
  const [vendorId] = fullId.split("#");
  return vendorId;
};

const Info: React.FC<CartProps> = ({ setCartLength }) => {
  const [data, setData] = useState<DataItems[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<any>(
          "https://643vhoswl2.execute-api.us-west-2.amazonaws.com/get-product?Category=Restaurant"
        );
        const responseData = response.data;

        if (responseData?.products) {
          setData(responseData.products);
        } else {
          console.error("Unexpected response format:", responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleAddToCart = async (
    price_id: string,
    name: string,
    price: string,
    primary_image_link: string,
    PK: string
  ) => {
    try {
      const userId = await cognitoService.getUserIdFromSession();
      const status = "cart";
      const transactionId = generateUniqueTransactionId(status);

      if (!userId) {
        alert("User ID not found. User may not be authenticated.");
        return;
      }

      const vendorId = extractVendorId(PK); // Extract vendorId

      // Retrieve event details from localStorage
      const eventDetails = localStorage.getItem("eventDetails");
      let eventData = null;
      if (eventDetails) {
        eventData = JSON.parse(eventDetails);
      } else {
        alert(
          "Event details not found in localStorage. Please fill in the form."
        );
        return;
      }

      // Prepare the cart item data
      const items: CartItem[] = [
        {
          vendorId: vendorId,
          priceId: price_id,
          price: price,
          name: name,
          primary_image_link: primary_image_link,
          quantity: 1,
        },
      ];

      // Combine event details with cart items
      const payload: AddToCartPayload & { eventData: any } = {
        userID: userId,
        Items: items,
        status: status,
        transactionId: transactionId,
        eventData, // Add event details from localStorage
      };

      const apiUrl =
        "https://ni7q14skkl.execute-api.us-west-2.amazonaws.com/demo/create-cart-data";

      // Send the payload to the API
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Cart updated successfully:", response.data);

      setCartLength((prevLength) => {
        const newLength = prevLength + 1;
        localStorage.setItem("cartLength", newLength.toString()); // Save to local storage
        return newLength;
      });
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert("There was an error adding the item to the cart.");
    }
  };

  return (
    <>
      <Filter />
      <div className="mx-3">
        <div className="mx-">
          {data?.map((item) => {
            const { name, price, primary_image_link, PK, price_id } = item;
            return (
              <Card
                key={PK}
                className="d-flex flex-row"
                style={{
                  maxWidth: 800,
                  margin: "10px 10em",
                  alignItems: "center",
                }}
              >
                <Card.Img
                  variant="top"
                  src={primary_image_link}
                  alt={name}
                  style={{ width: "12em", objectFit: "cover" }}
                />
                <Card.Body style={{ marginLeft: "20px", flexGrow: 1 }}>
                  <Card.Title>{name}</Card.Title>
                  <Card.Text>Price: ${price}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleAddToCart(
                        price_id,
                        name,
                        price,
                        primary_image_link,
                        PK
                      )
                    }
                  >
                    Add to Cart
                  </Button>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Info;
