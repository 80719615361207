import React from "react";
import Footer from "../../Components/Footer/Footer";
import EventCard from "../../Components/Card/Event";
import { Container, Row } from "react-bootstrap";
import Slideshow from "../../Components/SlideShow/SlideShowSlide";
import Navbar from "../../Components/Navbar/Navbar";
const getTokensFromLocalStorage = (): {
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
} => {
  const accessToken = localStorage.getItem("accessToken");
  const idToken = localStorage.getItem("idToken");
  const refreshToken = localStorage.getItem("refreshToken");

  return { accessToken, idToken, refreshToken };
};
const Events: React.FC = () => {
  return (
    <>
      <Container fluid>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          <EventCard
            title=""
            details=""
            description="Join us for a day filled with fun and surprises as we celebrate a special birthday."
            imageUrl="https://media.istockphoto.com/id/1479789881/photo/boy-blowing-birthday-candles-on-a-birtday-party.jpg?s=1024x1024&w=is&k=20&c=J1sKk7R6t9TDQXAjDMmWuy16Hi1CAjN1mn-ibNmNEu0="
            linkTo="/WelcomePage"
          />
          <EventCard
            description="Subscribe to your community events you like!!"
            imageUrl="https://ienglishstatus.com/wp-content/uploads/2016/12/Marriage-Anniversary-Pics.jpg"
            linkTo="/WelcomePage"
          />
          <EventCard
            title="Retirement function"
            description="Celebrate someone's birthday with joy and laughter."
            details="Join us for a day filled with fun and surprises as we celebrate a special birthday."
            imageUrl="https://th.bing.com/th/id/OIP.IO41LFRN7UZWEs-A-Js9OgHaE8?w=237&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/WelcomePage"
          />
          <EventCard
            title="Other services"
            description="Join us in celebrating the union of two beautiful souls."
            details="Witness the love and commitment as two souls come together in matrimony."
            imageUrl="https://th.bing.com/th/id/OIP.5BFJ-S6zqlxRIapsnBAXvwHaE8?rs=1&pid=ImgDetMain"
            linkTo="/WelcomePage"
          />
          <EventCard
            title="Custom celebration"
            description="Celebrate someone's birthday with joy and laughter."
            details="Join us for a day filled with fun and surprises as we celebrate a special birthday."
            imageUrl="https://th.bing.com/th/id/OIP.0ihxulEFmEXscYZdzV7LTAHaEK?w=260&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/WelcomePage"
          />
          <EventCard
            title="grad celebration"
            description="Join us in celebrating the union of two beautiful souls."
            details="Witness the love and commitment as two souls come together in matrimony."
            imageUrl="https://th.bing.com/th/id/OIP.f_y5zMHGx5yB8awiqdKDWgHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/WelcomePage"
          />
        </Row>
        {/* <Slideshow slides={slides} /> */}
      </Container>
    </>
  );
};

export default Events;
