import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import cognitoService from "../Auth/cognitoService";

function ProductForm() {
  const [item, setItem] = useState({
    userId: "",
    category: "",
    name: "",
    price: "",
    primaryImage: "",
    images: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchUserId() {
      try {
        const userId = await cognitoService.getUserIdFromSession();
        if (!userId) {
          throw new Error("User ID not found. User may not be authenticated.");
        }
        setItem((prevItem) => ({ ...prevItem, userId }));
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchUserId();
  }, []);

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setItem((prevItem) => ({
      ...prevItem,
      [id]: value,
    }));
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    console.log(item);
    try {
      const response = await axios.post(
        "https://jvlnzkr06c.execute-api.us-west-2.amazonaws.com/prod-1/create-product",
        item
        // { headers: headers }
      );
      console.log(response);
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="category">
          <Form.Label>Product Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Product category"
            value={item.category}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Product name"
            value={item.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="price">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="text"
            placeholder="Price"
            value={item.price}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="primaryImage">
          <Form.Label>Primary Image link</Form.Label>
          <Form.Control
            type="text"
            placeholder="Primary image link"
            value={item.primaryImage}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="images">
          <Form.Label>Image links</Form.Label>
          <Form.Control
            type="text"
            placeholder="Images"
            value={item.images}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={item.description}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={handleClick}>
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default ProductForm;
