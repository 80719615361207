import React, { Component } from 'react'
import { useSearchParams } from 'react-router-dom';
import VendorProfile from './VendorProfile';
import UserProfile from './UserProfile';

const Profiles: React.FC = () => {
    const [searchParams] = useSearchParams();
    const userType = searchParams.get('usertype');
  

return (
    <>
    {
    userType==="vendor"?<VendorProfile />:(userType==="user"?<UserProfile />:<div>Enter correct user</div>)
    }
    </>
)
}

export default Profiles