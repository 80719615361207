import { useState, useEffect } from "react";
import EventCard from "../../../Components/Card/Event";
import { Container, Row } from "react-bootstrap";
import axios from "axios";

interface DataItems {
  name: string;
  price: string;
  image_links: string;
}

function Plan() {
  const [products, setProducts] = useState<DataItems[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<any>(
          "https://643vhoswl2.execute-api.us-west-2.amazonaws.com/get-product?Category=Restaurant"
        );
        const responseData = response.data;

        if (responseData && responseData.products) {
          console.log(responseData);
          setProducts(responseData.products);
        } else {
          console.error("Unexpected response format:", responseData);
        }
        // console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10px", marginBottom: "30px" }}
        >
          <EventCard
            title="Restaurants"
            details=""
            imageUrl="https://media.istockphoto.com/id/1479789881/photo/boy-blowing-birthday-candles-on-a-birtday-party.jpg?s=1024x1024&w=is&k=20&c=J1sKk7R6t9TDQXAjDMmWuy16Hi1CAjN1mn-ibNmNEu0="
            linkTo="/Restaurants"
          />
          <EventCard
            title="Photographers"
            imageUrl="https://ienglishstatus.com/wp-content/uploads/2016/12/Marriage-Anniversary-Pics.jpg"
            linkTo="/Photographers"
          />
          <EventCard
            title="Decoration"
            imageUrl="https://th.bing.com/th/id/OIP.IO41LFRN7UZWEs-A-Js9OgHaE8?w=237&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/Decoration"
          />
          <EventCard
            title="Venues"
            imageUrl="https://th.bing.com/th/id/OIP.5BFJ-S6zqlxRIapsnBAXvwHaE8?rs=1&pid=ImgDetMain"
            linkTo="/Venues"
          />
          <EventCard
            title="Activities"
            imageUrl="https://th.bing.com/th/id/OIP.0ihxulEFmEXscYZdzV7LTAHaEK?w=260&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/Activities"
          />
          <EventCard
            title="Celebrity Greetings"
            imageUrl="https://th.bing.com/th/id/OIP.f_y5zMHGx5yB8awiqdKDWgHaE8?w=255&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            linkTo="/Photographers"
          />
        </Row>
        {/* <Slideshow slides={slides} /> */}
      </Container>
    </>
  );
}

export default Plan;
