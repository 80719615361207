import React from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface EventCardProps {
  title?: string;
  description?: string;
  details?: string;
  imageUrl?: string;
  linkTo: string;
}

const EventCard: React.FC<EventCardProps> = ({
  title,
  description,
  imageUrl,
  linkTo,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(linkTo)}
      style={{
        cursor: "pointer",
        display: "inline-block",
        width: "18rem",
        marginRight: "20px",
        transition: "transform 0.3s ease-in-out",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      <Card
        style={{
          width: "100%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          height: "300px",
          marginTop: "20px",
        }}
      >
        <Image src={imageUrl} alt={title} fluid />
        <Card.Body>
          <Card.Title style={{ color: "#ADD8E6", fontWeight: "bold" }}>
            {title}
          </Card.Title>
          <Card.Text style={{ color: "#808080" }}>{description}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EventCard;
