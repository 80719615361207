import React, { useEffect, useState } from "react";
import axios from "axios";
import Subtotal from "../../../Components/Subtotal/Subtotal";
import cognitoService from "../../../Components/Auth/cognitoService";
import CartItemComponent from "../../../Components/Card/CardItem";

interface Item {
  priceId: string;
  quantity: number;
  name: string;
  primary_image_link: string;
  price: string;
}

interface EventDetails {
  eventName: string;
  numberOfGuests: number;
  eventDate: string;
  phoneNumber: string;
}

const Cart: React.FC = () => {
  const [cart, setCart] = useState<Item[] | null>(null);
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null); // Add eventDetails state
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const userId = await cognitoService.getUserIdFromSession();
        console.log(userId);
        if (!userId) {
          throw new Error("User ID not found. User may not be authenticated.");
        }

        const response = await axios.get(
          `https://ni7q14skkl.execute-api.us-west-2.amazonaws.com/get-cart-data?userId=${userId}`
        );

        if (response.data.cartItems.length > 0) {
          const cartData = response.data.cartItems[0];
          console.log(cartData.Items);
          setCart(cartData.Items);
          setEventDetails(response.data.eventDetails); // Set eventDetails from response
        }
      } catch (error) {
        setError("Error fetching cart data");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!cart) return <p>No Items in cart</p>;

  return (
    <div>
      <h1>Cart</h1>

      <h3>Items:</h3>
      <ul>
        {cart.length > 0 ? (
          cart.map((item) => {
            const data = {
              id: item.priceId,
              name: item.name,
              imageUrl: item.primary_image_link,
              quantity: item.quantity,
              price: item.price,
            };
            return (
              <li key={item.priceId}>
                <CartItemComponent item={data} />
              </li>
            );
          })
        ) : (
          <li>No items in the cart</li>
        )}
      </ul>

      <Subtotal items={cart} />

      {/* Display eventDetails if they exist */}
      {eventDetails && (
        <div>
          <h3>Event Details</h3>
          <p>
            <strong>Event Name:</strong> {eventDetails.eventName}
          </p>
          <p>
            <strong>Number of Guests:</strong> {eventDetails.numberOfGuests}
          </p>
          <p>
            <strong>Event Date:</strong> {eventDetails.eventDate}
          </p>
          <p>
            <strong>Phone Number:</strong> {eventDetails.phoneNumber}
          </p>
        </div>
      )}
    </div>
  );
};

export default Cart;
